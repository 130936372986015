import React from 'react';
import spiner from './New folder/spiner.gif';

export default function Spiner() {
  return (
    <div>
      <img alt="" src={spiner} />
    </div>
  );
}
