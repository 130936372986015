import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  let handleClick = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <footer
        className="text-center"
        style={{ backgroundColor: 'rgb(127 145 247 / 30%)' }}
      >
        <div className="container p-4">
          <section className="mb-4">
            <a
              className="btn btn-primary btn-floating m-1"
              style={{ backgroundColor: '#3b5998' }}
              href="https://www.facebook.com/"
              role="button"
              target="_blank" rel="noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>

            <a
              className="btn btn-primary btn-floating m-1"
              style={{ backgroundColor: '#55acee' }}
              href="https://twitter.com/i/flow/login"
              role="button"
              target="_blank" rel="noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>

            <a
              className="btn btn-primary btn-floating m-1"
              style={{ backgroundColor: '#dd4b39' }}
              href="https://myaccount.google.com/"
              role="button"
              target="_blank" rel="noreferrer"
            >
              <i className="fab fa-google"></i>
            </a>

            <a
              className="btn btn-primary btn-floating m-1"
              style={{ backgroundColor: '#ac2bac' }}
              href="https://www.instagram.com/accounts/login/"
              role="button"
              target="_blank" rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </section>

          <form onSubmit={handleClick}>
            <div className="col-md-12 d-flex flex-wrap justify-content-center">
              <div className="col-auto">
                <p className="pt-2">
                  <strong>Sign up for our newsletter</strong>
                </p>
              </div>

              <div className="col-md-5 col-12">
                <div className="form-outline mb-4">
                  <input
                    type="email"
                    id="form5Example2"
                    className="form-control"
                  />
                  <label className="form-label" htmlFor="form5Example2">
                    Email address
                  </label>
                </div>
              </div>

              <div className="col-auto">
                <button
                  type="submit"
                  className="btn mb-4"
                  style={{
                    backgroundColor: 'rgb(63, 81, 181)',
                    color: 'white',
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
              distinctio earum repellat quaerat voluptatibus placeat nam,
              commodi optio pariatur est quia magnam eum harum corrupti dicta,
              aliquam sequi voluptate quas.
            </p>
          </form>
        </div>

        <div
          className="text-center p-3"
          style={{ backgroundColor: 'rgb(28 65 146 / 91%)', color: 'white' }}
        >
          © 2020 Copyright:
          <span style={{ color: 'white', textDecoration: 'none' }}>
            G-News.com
          </span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
