import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { URL } from './env';
import './Sign.css';

export default function SignIn() {
  const [classes, setClasses] = useState('sign-up');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [con, setCon] = useState('');

  const [login, setLogin] = React.useState();
  const [emails, setEmails] = React.useState('');
  const [passw, setPassw] = React.useState('');

  const location = useLocation();
  let navigate = useNavigate();

  let loginData = () => {
    fetch(URL + 'get_data.php')
      .then((res) => res.json())
      .then((data) => setLogin(data));
  };

  let toggle = () => {
    if (classes === 'sign-up') {
      setClasses('sign-in');
    } else {
      setClasses('sign-up');
    }
  };
  let signUp = (e) => {
    let filters = login.filter(
      (v) => email  === v.uemail
    );
    console.log(filters);
    if (pass !== con) {
      e.preventDefault();
      swal({
        title: 'Sorry',
        text: 'Your Password Not Match',
        icon: 'warning',
        dangerMode: true,
      });
    }
    else if(filters[0]){
      e.preventDefault();
      swal({
        title: 'Sorry',
        text: 'Email Is Alredy Exist',
        icon: 'warning',
        dangerMode: true,
      });
    }
     else {
      localStorage.setItem('Name', name);
      location.pathname = '/';
    }
  };
  useEffect(() => {
    loginData();
  }, []);

  let loginClick = (e) => {
    // console.log(login)
    e.preventDefault();
    let fildata = login.filter(
      (v) => v.uemail === emails && v.upassword === passw
    );
    console.log(fildata);
    if (emails === '' || passw === '') {
      swal({
        text: 'Please Fill Values',
        icon: 'warning',
      });
    } else if (!fildata[0]) {
      swal({
        text: 'Fill Data is Incorrect',
        icon: 'warning',
      });
    } else if (fildata[0]) {
      localStorage.setItem('Email', emails);
      navigate('/');
    }

  };

  return (
    <>
      <div id="container" className={`${classes}`}>
        <div className="row">
          <div className="col align-items-center flex-col sign-up">
            <div className="form-wrapper align-items-center">
              <div className="form sign-up">
                <form action={URL + "signin.php"} method="post">
                  <div className="input-group">
                    <i className="bx bxs-user"></i>
                    <input
                      name="uname"
                      type="text"
                      placeholder="Username"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bx-mail-send"></i>
                    <input
                      name="uemail"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bxs-lock-alt"></i>
                    <input
                      name="upassword"
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPass(e.target.value)}
                      value={pass}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bxs-lock-alt"></i>
                    <input
                      name="uconform"
                      type="password"
                      placeholder="Confirm password"
                      onChange={(e) => setCon(e.target.value)}
                      value={con}
                      required
                    />
                  </div>

                  <button name="save" onClick={signUp}>
                    Sign up
                  </button>
                </form>
                <p>
                  <span>Already have an account?</span>
                  <b onClick={toggle} className="pointer">
                    Sign in here
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div className="col align-items-center flex-col sign-in">
            <div className="form-wrapper align-items-center">
              <div className="form sign-in">
                <form onSubmit={loginClick}>
                  <div className="input-group">
                    <i className="bx bxs-user"></i>
                    <input
                      type="text"
                      placeholder="Email"
                      onChange={(e) => setEmails(e.target.value)}
                      value={emails}
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bxs-lock-alt"></i>
                    <input
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPassw(e.target.value)}
                      value={passw}
                    />
                  </div>
                  <button type="submit">Sign in</button>
                  <p>
                    <b>Forgot password?</b>
                  </p>
                  <p>
                    <span>Don't have an account?</span>
                    <b onClick={toggle} className="pointer">
                      Sign up here
                    </b>
                  </p>
                </form>
              </div>
            </div>
            <div className="form-wrapper"></div>
          </div>
        </div>
        <div className="row content-row">
          <div className="col align-items-center flex-col">
            <div className="text sign-in text-light">
              <h2>Welcome</h2>
            </div>
            <div className="img sign-in"></div>
          </div>
          <div className="col align-items-center flex-col">
            <div className="img sign-up"></div>
            <div className="text sign-up">
              <h2 className="text-light">Join with us</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
