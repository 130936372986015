import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import VideoCameraBackRoundedIcon from '@mui/icons-material/VideoCameraBackRounded';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { URL } from './env';

let ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(false);
  const [dbms, setDbms] = React.useState();

  let navigation = useNavigate();
  let location = useLocation();

  let fetchData = async () => {
    // await fetch(URL+'get_data.php')
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if(data[0]){
    //       setDbms(data[0].uname || data)
    //       localStorage.setItem('Name',data[0].uname)
    //     }
    //   });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // eslint-disable-next-line no-unused-vars
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(!anchorElUser);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // eslint-disable-next-line no-unused-vars
  const handleCloseUserMenu = () => {
    setAnchorElUser(!anchorElUser);
  };

  let signOut = () => {
    localStorage.removeItem("Name");
    navigation('/');
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <VideoCameraBackRoundedIcon
              sx={{
                display: { xs: 'none', md: 'flex' },
                mr: 1,
                fontSize: '40px',
              }}
            />
            <Typography
              variant="h6"
              noWrap
              href=""
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                color: 'inherit',
                fontWeight: '700',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              G-Times
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <Link to="">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Home</Typography>
                  </MenuItem>
                </Link>
                <Link to="/news">
                  <MenuItem>
                    <Typography textAlign="center">News</Typography>
                  </MenuItem>
                </Link>
                <Link to="/blogs">
                  <MenuItem>
                    <Typography textAlign="center">Blog</Typography>
                  </MenuItem>
                </Link>
                <Link to="/About">
                  <MenuItem>
                    <Typography textAlign="center">About</Typography>
                  </MenuItem>
                </Link>
              </Menu>
            </Box>
            <VideoCameraBackRoundedIcon
              sx={{
                display: { xs: 'flex', md: 'none' },
                mr: 1,
                fontSize: '40px',
              }}
            />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              G-Times
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Link to="" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block', textDecoration: 'none' }}
                >
                  <span style={{ color: 'white' }}>Home</span>
                </Button>
              </Link>
              <Link to="/news" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block' }}
                >
                  <span style={{ color: 'white' }}>News</span>
                </Button>
              </Link>
              <Link to="/blogs" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block' }}
                >
                  <span style={{ color: 'white' }}>Blog</span>
                </Button>
              </Link>
              <Link to="/About" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block' }}
                >
                  <span style={{ color: 'white' }}>About</span>
                </Button>
              </Link>
            </Box>

            <Link to="/Signin" style={{ textDecoration: 'none' }}>
              {' '}
              <Typography textAlign="center" style={{ color: 'white' }}>
                {location.pathname === '/Signin'
                  ? ''
                  : 'Sign in' && localStorage.getItem('Name')
                  ? localStorage.getItem('Name')
                  : localStorage.getItem('Email') || 'Sign In'}
              </Typography>
            </Link>

            {location.pathname === '/Signin' ? (
              ''
            ) : (
              <Button
                style={{
                  // color: 'white',
                  marginLeft: '20px',
                  backgroundColor: 'white',
                  color: '#1976d2',
                  fontWeight: 'bold',
                }}
              >
                {localStorage.getItem('Name') ? (
                  <span onClick={signOut}>
                    <a
                      style={{ textDecoration: 'none' }}
                      
                    >
                      sign out
                    </a>
                  </span>
                ) : (
                  <span>
                    {!localStorage.getItem('Email') ? (
                      <Link to="/Signin" style={{ textDecoration: 'none' }}>
                        login
                      </Link>
                    ) : (
                      <span
                        onClick={() => {
                          localStorage.clear();
                          setAnchorElUser(!anchorElUser);
                        }}
                        style={{ textDecoration: 'none' }}
                      >
                        Logout
                      </span>
                    )}
                  </span>
                )}
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default ResponsiveAppBar;
