import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import './App.css';
import ResponsiveAppBar from './Navbar';
import SignIn from './SignIn';
import News from './news';
import Blogs from './Blogs';
import About from './About';


function App() {
  return (
    <>
  
    <ResponsiveAppBar />
      <Routes>
        <Route exact path='' element={<Home/>}/>
        <Route exact path='/signin' element={<SignIn/>}/>
        <Route exact path='/news' element={<News/>}/>
        <Route exact path='/blogs' element={<Blogs/>}/>
        <Route exact path='/about' element={<About/>}/>
      </Routes>
    </>
  );
}

export default App;
