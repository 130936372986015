import * as React from 'react';
import './slick.css';

export default function Slick() {
  let dailyNews = [
    {
      title: 'Technology',
      name: 'Light Ultra Powerful Laptop In 21 Gen',
      url: 'https://rarathemesdemo-147ed.kxcdn.com/metro-magazine/wp-content/uploads/sites/34/2016/09/photo-1453133451515-5ff7c1d0d63c-360x246.jpg',
      color: 'rgb(54 105 217)',
    },
    {
      title: 'Sports',
      name: 'Why is Basketball so famous ?',
      url: 'https://rarathemesdemo-147ed.kxcdn.com/metro-magazine/wp-content/uploads/sites/34/2016/09/basketball-95607_1280-360x246.jpg',
      color: 'rgb(54 105 217)',
    },
    {
      title: 'Sports',
      name: 'Rideing Wave on a Bodyboard',
      url: 'https://rarathemesdemo-147ed.kxcdn.com/metro-magazine/wp-content/uploads/sites/34/2016/09/photo-1470072658328-919ef048ae6f-360x246.jpg',
      color: 'rgb(54 105 217)',
    },
    {
      title: 'Travel',
      name: 'Tips for travelling with an infant',
      url: 'https://rarathemesdemo-147ed.kxcdn.com/metro-magazine/wp-content/uploads/sites/34/2016/11/mother-84628_1280-360x246.jpg',
      color: 'rgb(54 105 217)',
    },
    {
      title: 'Tours',
      name: 'Best Way to have Fun During Roadtrip',
      url: 'https://rarathemesdemo-147ed.kxcdn.com/metro-magazine/wp-content/uploads/sites/34/2016/09/vw-camper-336606_1280-360x246.jpg',
      color: 'rgb(54 105 217)',
    },
    {
      title: 'Helth',
      name: 'Health Benifits of Yoga For Helth',
      url: 'https://rarathemesdemo-147ed.kxcdn.com/metro-magazine/wp-content/uploads/sites/34/2016/09/photo-1470919543690-22e21c13163d-360x246.jpg',
      color: 'rgb(54 105 217)',
    },
  ];
  return (
    <>
      <div
        className="container mt-2"
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        {dailyNews.map((curEle, i) => {
          return (
            <div key={i} className="card m-3" style={{ width: '18rem' }}>
              <img alt="" className="card-img-top" src={curEle.url} />

              <div className="card-body">
                <h3>{curEle.name}</h3>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <span
                style={{ background: curEle.color, fontSize: '15px' }}
                className="badge"
              >
                {curEle.title}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
}
