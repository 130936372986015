import { useEffect, useState } from 'react';
import './App.css';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
// import TableCoins from './Tablecoins';

let pages = 15;

function Table() {
  const [coins, setCoins] = useState([]);
  const [curPag, setCurpag] = useState(0);
  const [loding, setLogin] = useState(false);

  const getData = async () => {
    setLogin(true);
    const res = await axios.get(
      'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1'
    );
    setLogin(false);
    setCoins(res.data);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      getData();
    }, 60000);
    getData();
  }, []);

  function hendalPageclick({ selected: selectedPage }) {
    // console.log("first", selectedPage);
    setCurpag(selectedPage);
  }

  let offset = curPag * pages;
  let curPagedata = coins.slice(offset, offset + pages).map((val, index) => (
    <tr key={index}>
      <th>{val.id}</th>
      <td>
        <img alt="" className="mx-3" width="25px" src={val.image} />
        <span>{val.name}</span> -{' '}
        <span className="mx-1" style={{ color: 'rgb(139, 139, 139)' }}>
          {val.symbol.toUpperCase()}
        </span>
      </td>
      <td
        className={`${val.current_price > 0 ? 'text-success' : 'text-danger'}`}
      >
        ${val.current_price.toFixed(2)}
      </td>
      <td
        className={`${
          val.price_change_percentage_24h > 0 ? 'text-success' : 'text-danger'
        }`}
      >
        {val.price_change_percentage_24h.toFixed(2)}%
      </td>
      <td
        className={`${
          val.market_cap_change_percentage_24h > 0
            ? 'text-success'
            : 'text-danger'
        }`}
      >
        {val.market_cap_change_percentage_24h.toFixed(2)}%
      </td>
      <td
        className={`${val.total_volume > 0 ? 'text-success' : 'text-danger'}`}
      >
        ${val.total_volume}
      </td>
      <td className={`${val.market_cap > 0 ? 'text-success' : 'text-danger'}`}>
        ${val.market_cap}
      </td>
    </tr>
  ));

  let pagecounts = Math.ceil(coins.length / pages);

  return (
    <>
      {loding ? (
        'Loding...'
      ) : (
        <table
          className="table container"
          style={{ border: '1px solid black' }}
        >
          <thead
            className="table"
            style={{ backgroundColor: 'rgb(28 65 146)', color: 'white' }}
          >
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Price</th>
              <th scope="col">24h</th>
              <th scope="col">7d</th>
              <th scope="col">24h Volume</th>
              <th scope="col">Mkt Cap </th>
            </tr>
          </thead>

          <tbody
            style={{
              backgroundColor: 'rgb(252, 252, 252)',
              border: '1px solid black',
            }}
            className="loding"
          >
            {curPagedata}
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-center pagination">
        <ReactPaginate
          pageCount={pagecounts}
          onPageChange={hendalPageclick}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default Table;
