import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import first from './New folder/1.jpg';
import second from './New folder/2.jpg';
import third from './New folder/3.jpg';
import fourth from './New folder/4.jpg';
import fifth from './New folder/5.jpg';
import vector from './New folder/5.png';
import n1 from './New folder/n1.jpg';
import n2 from './New folder/n2.jpg';
import n3 from './New folder/n3.jpg';
import n4 from './New folder/n4.jpg';
import n5 from './New folder/n5.jpg';
import n6 from './New folder/n6.jpg';
import n7 from './New folder/n7.jpg';
import TextField from '@mui/material/TextField';
import Slick from './slick';
import './home.css';
import Recent from './recent';
import Footer from './footer';
import CustomizedDialogs from './Popup';
import { Link, useNavigate } from 'react-router-dom';

import swal from 'sweetalert';
import { URL } from './env';

export default function Home() {
  const images = [
    { url: first },
    { url: second },
    { url: third },
    { url: fourth },
    { url: fifth },
  ];
  const [name, setName] = React.useState('');
  let location = useNavigate()
 

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: 'ease-in-sine',
      delay: 100,
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  let nameClick = (e) =>{
    
   if (!name) {
    e.preventDefault()
    swal({
      title: 'Sorry',
      text: 'Please Insert Name',
      icon: 'warning',
      dangerMode: true,
    });
   }
   else{
    location('/')
   }
  }
  let news = [
    {
      name: 'Hindi-Khabar',
      rating: '⭐⭐⭐',
      url: n1,
      weblink: 'https://hindikhabar.com/',
    },
    {
      name: 'Aaj-Tak',
      rating: '⭐⭐⭐⭐⭐',
      url: n2,
      weblink: 'https://www.aajtak.in/',
    },
    {
      name: 'ND-T.V',
      rating: '⭐⭐⭐⭐',
      url: n3,
      weblink: 'https://www.indiatvnews.com/',
    },
    {
      name: 'TV-9',
      rating: '⭐⭐⭐',
      url: n4,
      weblink: 'https://tv9.com/',
    },
    {
      name: 'ZEE-News',
      rating: '⭐⭐',
      url: n5,
      weblink: 'https://zeenews.india.com/',
    },
    {
      name: 'ABP Asmita',
      rating: '⭐⭐⭐⭐⭐',
      url: n6,
      weblink: 'https://gujarati.abplive.com/',
    },
    {
      name: 'DD-News',
      rating: '⭐⭐⭐⭐',
      url: n7,
      weblink: 'https://ddnews.gov.in/',
    },
  ];
  
  return (
    <>
      <CustomizedDialogs />
      <div
        className="pt-3"
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '40px',
          color: 'rgb(54, 105, 217)',
          fontWeight: 'bold',
        }}
      >
        Gujrat Times
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <div className="demo-content justify-content-center">
            <img
              src={vector}
              width="90%"
              alt="about"
              className="img-responsive"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="demo-content bg-alt mt-5 pt-5 justify-content-center">
            <h1 className="pb-4 pt-5">
              "The Bad News Is Time Flies
              <br /> The Good News Is Your Pilot"
            </h1>
            <p id="demo">
              {' '}
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised
              in the 1960s with the release of Letraset sheets containing Lorem
              psum passages, and more recently with desktop publishing software
              like Aldus PageMaker including versions of Lorem Ipsum.
            </p>

            <div className="mt-4">
              <form action={URL+"name.php"} method="post">
                <TextField
                  name='nam'
                  id="outlined-textarea"
                  label="Enter Your Name"
                  placeholder="Placeholder"
                  size="small"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <Button
                  type='submit'
                  className="mx-4 pt-2"
                  variant="contained"
                  style={{ background: 'rgb(54 105 217)', color: 'white' }}
                  onClick={nameClick}
                >
                  Submit
                </Button>
              </form>
            </div>
            <Link to="/about" style={{ textDecoration: 'none' }}>
              <Button
                className="mt-4"
                variant="contained"
                style={{ backgroundColor: 'rgb(54 105 217)', color: 'white' }}
              >
                Contact Us
              </Button>
            </Link>
          </div>
        </div>

        <div className="pt-4">
          <Slider {...settings}>
            {images.map((imgs, i) => {
              return (
                <div key={i}>
                  <img alt="" width="100%" src={imgs.url} />
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="mt-5 " style={{ background: '#f4f4f4' }}>
          <div
            className="text-center mt-3 "
            style={{
              fontWeight: 'bold',
              fontSize: '40px',
              color: 'rgb(54 105 217)',
            }}
          >
            News Channels
          </div>

          <div className="d-flex flex-wrap justify-content-center">
            {news.map((news, i) => {
              return (
                <div
                  key={i}
                  className="card mt-4 mb-4 mx-5"
                  style={{ width: '18rem' }}
                >
                  <img alt="" className="card-img-top" src={news.url} />
                  <div className="card-body">
                    <h5 className="card-title">{news.name}</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                    <p>Ratings : {news.rating}</p>
                    <a
                      href={news.weblink}
                      target="_blank"
                      className="btn"
                      style={{ background: 'rgb(54 105 217)', color: 'white' }}
                      rel="noreferrer"
                    >
                      Go somewhere
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-3">
          <div
            className="text-center pt-4"
            style={{
              fontWeight: 'bold',
              fontSize: '40px',
              color: 'rgb(54 105 217)',
            }}
          >
            Top Head Lines
          </div>
          <div className="pt-4">
            <Slick />
          </div>
          <div
            className="text-center pt-4"
            style={{
              fontWeight: 'bold',
              fontSize: '40px',
              color: 'rgb(54 105 217)',
            }}
          >
            Crypto News
          </div>
          <div className="pt-5">
            <Recent />
          </div>

          <div className="pt-4">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
