import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomizedDialogs from './Popup';
// import { Link } from 'react-router-dom';
import Footer from './footer';
import Spiner from './Spiner';

export default function News() {
  let data = [
    {
      name: 'local',
      url: 'https://saurav.tech/NewsAPI/everything/cnn.json',
    },
    {
      name: 'general',
      url: 'https://saurav.tech/NewsAPI/top-headlines/category/general/in.json',
    },
    {
      name: 'business',
      url: 'https://saurav.tech/NewsAPI/top-headlines/category/business/in.json',
    },
    {
      name: 'entertainment',
      url: 'https://saurav.tech/NewsAPI/top-headlines/category/entertainment/in.json',
    },

    {
      name: 'health',
      url: 'https://saurav.tech/NewsAPI/top-headlines/category/health/in.json',
    },
    {
      name: 'science',
      url: 'https://saurav.tech/NewsAPI/top-headlines/category/science/in.json',
    },
    {
      name: 'sports',
      url: 'https://saurav.tech/NewsAPI/top-headlines/category/sports/in.json',
    },
    {
      name: 'technology',
      url: 'https://saurav.tech/NewsAPI/top-headlines/category/technology/in.json',
    },
  ];
  const [url, setUrl] = useState(
    'https://saurav.tech/NewsAPI/everything/cnn.json'
  );
  const [state, setState] = useState([]);
  const [loding, setLoding] = useState(false);
  const [name, setName] = useState("Top")

  let getData = async () => {
    setLoding(true);
    let fetchs = await fetch(url);
    let data = await fetchs.json();
    setLoding(false);
    setState(data.articles);
  };

  useEffect(() => {
    getData();
  }, [url]);
console.log(state)
  let change = (name) => {
    document.body.scrollTop = 0;
    setLoding(true);
    let update = data.filter((n) => n.name === name);
    setName(update[0].name)
    setLoding(false);
    setUrl(update[0].url);
  };

  let imgs =
    'https://admin.open-contracting.health/media/original_images/news-1.jpg';

  return (
    <>
      {loding ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spiner />
        </div>
      ) : (
        <>
          <div
            className="pt-3"
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '40px',
              color: '#065bb0',
              fontWeight: 'bold',
            }}
          >
            {/* {' '} */}
           {name} news
          </div>
            
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {state.map((ele, i) => (
              <div key={i} style={{ margin: '15px' }}>
                <Card
                  className="card"
                  sx={{
                    maxWidth: 345,
                    height: '350px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={ele.urlToImage ? ele.urlToImage : imgs}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="text-truncate"
                    >
                      {ele.author ? ele.author : 'Not Found'}
                    </Typography>
                    <Typography
                      paragraph={true}
                      variant="body2"
                      color="text.secondary"
                    >
                      {ele.description
                        ? ele.description
                        : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'}
                    </Typography>
                  </CardContent>
                  <CardActions style={{ alignItems: 'end' }}>
                    <Button size="small">Share</Button>
                    <Button style={{ backgroundColor: '#1976d2' }} size="small">
                      <a
                        href={ele.url}
                        style={{ color: 'white', textDecoration: 'none' }}
                        target="_blank" rel="noreferrer"
                      >
                        Learn More
                      </a>
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ))}
          </div>

          <div className="pt-4 pb-4">
            <nav aria-label="Page navigation example " className="container">
              <ul className="pagination justify-content-center">
                <li
                  className="page-link mx-1"
                  onClick={() => change('local')}
                  style={{
                    backgroundColor: 'rgb(25 118 210)',
                    borderRadius: '8px',
                    textDecoration: 'none',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  Local
                </li>
                <li
                  className="page-link mx-1"
                  onClick={() => change('general')}
                  style={{
                    backgroundColor: 'rgb(25 118 210)',
                    borderRadius: '8px',
                    textDecoration: 'none',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  General
                </li>
                <li
                  className="page-link mx-1"
                  onClick={() => change('business')}
                  style={{
                    backgroundColor: 'rgb(25 118 210)',
                    borderRadius: '8px',
                    textDecoration: 'none',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  Business
                </li>
                <li
                  className="page-link mx-1"
                  onClick={() => change('entertainment')}
                  style={{
                    backgroundColor: 'rgb(25 118 210)',
                    borderRadius: '8px',
                    textDecoration: 'none',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  Entertainment
                </li>
                <li
                  className="page-link mx-1"
                  onClick={() => change('health')}
                  style={{
                    backgroundColor: 'rgb(25 118 210)',
                    borderRadius: '8px',
                    textDecoration: 'none',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  Health
                </li>
                <li
                  className="page-link mx-1"
                  onClick={() => change('science')}
                  style={{
                    backgroundColor: 'rgb(25 118 210)',
                    borderRadius: '8px',
                    textDecoration: 'none',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  Science
                </li>
                <li
                  className="page-link mx-1"
                  onClick={() => change('sports')}
                  style={{
                    backgroundColor: 'rgb(25 118 210)',
                    borderRadius: '8px',
                    textDecoration: 'none',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  Sports
                </li>
                <li
                  className="page-link mx-1"
                  onClick={() => change('technology')}
                  style={{
                    backgroundColor: 'rgb(25 118 210)',
                    borderRadius: '8px',
                    textDecoration: 'none',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  Technology
                </li>
              </ul>
            </nav>
          </div>
          <CustomizedDialogs />

          <Footer />
        </>
      )}
    </>
  );
}
